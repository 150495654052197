export default [
  {
    id: 1,
    name: "Tiger Nixon",
    position: "System Architect",
    city: "Edinburgh",
    age: "61",
    createdAt: "2011/04/25",
    salary: "$320,800",
  },
  {
    id: 2,
    name: "Garrett Winters",
    position: "Accountant",
    city: "Tokyo",
    age: "63",
    createdAt: "2011/07/25",
    salary: "$170,750",
  },
  {
    id: 3,
    name: "Ashton Cox",
    position: "Junior Technical Author",
    city: "San Francisco",
    age: "66",
    createdAt: "2009/01/12",
    salary: "$86,000",
  },
  {
    id: 4,
    name: "Cedric Kelly",
    position: "Senior Javascript Developer",
    city: "Edinburgh",
    age: "22",
    createdAt: "2012/03/29",
    salary: "$433,060",
  },
  {
    id: 5,
    name: "Airi Satou",
    position: "Accountant",
    city: "Tokyo",
    age: "33",
    createdAt: "2008/11/28",
    salary: "$162,700",
  },
  {
    id: 6,
    name: "Brielle Williamson",
    position: "Integration Specialist",
    city: "New York",
    age: "61",
    createdAt: "2012/12/02",
    salary: "$372,000",
  },
  {
    id: 7,
    name: "Herrod Chandler",
    position: "Sales Assistant",
    city: "San Francisco",
    age: "59",
    createdAt: "2012/08/06",
    salary: "$137,500",
  },
  {
    id: 8,
    name: "Rhona Davidson",
    position: "Integration Specialist",
    city: "Tokyo",
    age: "55",
    createdAt: "2010/10/14",
    salary: "$327,900",
  },
  {
    id: 9,
    name: "Colleen Hurst",
    position: "Javascript Developer",
    city: "San Francisco",
    age: "39",
    createdAt: "2009/09/15",
    salary: "$205,500",
  },
  {
    id: 10,
    name: "Sonya Frost",
    position: "Software Engineer",
    city: "Edinburgh",
    age: "23",
    createdAt: "2008/12/13",
    salary: "$103,600",
  },
  {
    id: 11,
    name: "Jena Gaines",
    position: "Office Manager",
    city: "London",
    age: "30",
    createdAt: "2008/12/19",
    salary: "$90,560",
  },
  {
    id: 12,
    name: "Quinn Flynn",
    position: "Support Lead",
    city: "Edinburgh",
    age: "22",
    createdAt: "2013/03/03",
    salary: "$342,000",
  },
  {
    id: 13,
    name: "Charde Marshall",
    position: "Regional Director",
    city: "San Francisco",
    age: "36",
    createdAt: "2008/10/16",
    salary: "$470,600",
  },
  {
    id: 14,
    name: "Haley Kennedy",
    position: "Senior Marketing Designer",
    city: "London",
    age: "43",
    createdAt: "2012/12/18",
    salary: "$313,500",
  },
  {
    id: 15,
    name: "Tatyana Fitzpatrick",
    position: "Regional Director",
    city: "London",
    age: "19",
    createdAt: "2010/03/17",
    salary: "$385,750",
  },
  {
    id: 16,
    name: "Michael Silva",
    position: "Marketing Designer",
    city: "London",
    age: "66",
    createdAt: "2012/11/27",
    salary: "$198,500",
  },
  {
    id: 17, name: "Paul Byrd",
    position: "Chief Financial Officer (CFO)",
    city: "New York",
    age: "64",
    createdAt: "2010/06/09",
    salary: "$725,000",
  },
  {
    id: 18, name: "Gloria Little",
    position: "Systems Administrator",
    city: "New York",
    age: "59",
    createdAt: "2009/04/10",
    salary: "$237,500",
  },
  {
    id: 19, name: "Bradley Greer",
    position: "Software Engineer",
    city: "London",
    age: "41",
    createdAt: "2012/10/13",
    salary: "$132,000",
  },
  {
    id: 20, name: "Dai Rios",
    position: "Personnel Lead",
    city: "Edinburgh",
    age: "35",
    createdAt: "2012/09/26",
    salary: "$217,500",
  },
  {
    id: 21, name: "Jenette Caldwell",
    position: "Development Lead",
    city: "New York",
    age: "30",
    createdAt: "2011/09/03",
    salary: "$345,000",
  },
  {
    id: 22, name: "Yuri Berry",
    position: "Chief Marketing Officer (CMO)",
    city: "New York",
    age: "40",
    createdAt: "2009/06/25",
    salary: "$675,000",
  },
  {
    id: 23, name: "Caesar Vance",
    position: "Pre-Sales Support",
    city: "New York",
    age: "21",
    createdAt: "2011/12/12",
    salary: "$106,450",
  },
  {
    id: 24, name: "Doris Wilder",
    position: "Sales Assistant",
    city: "Sidney",
    age: "23",
    createdAt: "2010/09/20",
    salary: "$85,600",
  },
  {
    id: 25, name: "Angelica Ramos",
    position: "Chief Executive Officer (CEO)",
    city: "London",
    age: "47",
    createdAt: "2009/10/09",
    salary: "$1,200,000",
  },
  {
    id: 26, name: "Gavin Joyce",
    position: "Developer",
    city: "Edinburgh",
    age: "42",
    createdAt: "2010/12/22",
    salary: "$92,575",
  },
  {
    id: 27, name: "Jennifer Chang",
    position: "Regional Director",
    city: "Singapore",
    age: "28",
    createdAt: "2010/11/14",
    salary: "$357,650",
  },
  {
    id: 28, name: "Brenden Wagner",
    position: "Software Engineer",
    city: "San Francisco",
    age: "28",
    createdAt: "2011/06/07",
    salary: "$206,850",
  },
  {
    id: 29, name: "Fiona Green",
    position: "Chief Operating Officer (COO)",
    city: "San Francisco",
    age: "48",
    createdAt: "2010/03/11",
    salary: "$850,000",
  },
  {
    id: 30, name: "Shou Itou",
    position: "Regional Marketing",
    city: "Tokyo",
    age: "20",
    createdAt: "2011/08/14",
    salary: "$163,000",
  },
  {
    id: 31, name: "Michelle House",
    position: "Integration Specialist",
    city: "Sidney",
    age: "37",
    createdAt: "2011/06/02",
    salary: "$95,400",
  },
  {
    id: 32, name: "Suki Burks",
    position: "Developer",
    city: "London",
    age: "53",
    createdAt: "2009/10/22",
    salary: "$114,500",
  },
  {
    id: 33, name: "Prescott Bartlett",
    position: "Technical Author",
    city: "London",
    age: "27",
    createdAt: "2011/05/07",
    salary: "$145,000",
  },
  {
    id: 34, name: "Gavin Cortez",
    position: "Team Leader",
    city: "San Francisco",
    age: "22",
    createdAt: "2008/10/26",
    salary: "$235,500",
  },
  {
    id: 35, name: "Martena Mccray",
    position: "Post-Sales support",
    city: "Edinburgh",
    age: "46",
    createdAt: "2011/03/09",
    salary: "$324,050",
  },
  {
    id: 36, name: "Unity Butler",
    position: "Marketing Designer",
    city: "San Francisco",
    age: "47",
    createdAt: "2009/12/09",
    salary: "$85,675",
  },
  {
    id: 37, name: "Howard Hatfield",
    position: "Office Manager",
    city: "San Francisco",
    age: "51",
    createdAt: "2008/12/16",
    salary: "$164,500",
  },
  {
    id: 38, name: "Hope Fuentes",
    position: "Secretary",
    city: "San Francisco",
    age: "41",
    createdAt: "2010/02/12",
    salary: "$109,850",
  },
  {
    id: 59, name: "Vivian Harrell",
    position: "Financial Controller",
    city: "San Francisco",
    age: "62",
    createdAt: "2009/02/14",
    salary: "$452,500",
  },
  {
    id: 39, name: "Timothy Mooney",
    position: "Office Manager",
    city: "London",
    age: "37",
    createdAt: "2008/12/11",
    salary: "$136,200",
  },
  {
    id: 40, name: "Jackson Bradshaw",
    position: "Director",
    city: "New York",
    age: "65",
    createdAt: "2008/09/26",
    salary: "$645,750",
  },
  {
    id: 41, name: "Olivia Liang",
    position: "Support Engineer",
    city: "Singapore",
    age: "64",
    createdAt: "2011/02/03",
    salary: "$234,500",
  },
  {
    id: 42, name: "Bruno Nash",
    position: "Software Engineer",
    city: "London",
    age: "38",
    createdAt: "2011/05/03",
    salary: "$163,500",
  },
  {
    id: 43, name: "Sakura Yamamoto",
    position: "Support Engineer",
    city: "Tokyo",
    age: "37",
    createdAt: "2009/08/19",
    salary: "$139,575",
  },
  {
    id: 44, name: "Thor Walton",
    position: "Developer",
    city: "New York",
    age: "61",
    createdAt: "2013/08/11",
    salary: "$98,540",
  },
  {
    id: 45, name: "Finn Camacho",
    position: "Support Engineer",
    city: "San Francisco",
    age: "47",
    createdAt: "2009/07/07",
    salary: "$87,500",
  },
  {
    id: 46, name: "Serge Baldwin",
    position: "Data Coordinator",
    city: "Singapore",
    age: "64",
    createdAt: "2012/04/09",
    salary: "$138,575",
  },
  {
    id: 47, name: "Zenaida Frank",
    position: "Software Engineer",
    city: "New York",
    age: "63",
    createdAt: "2010/01/04",
    salary: "$125,250",
  },
  {
    id: 48, name: "Zorita Serrano",
    position: "Software Engineer",
    city: "San Francisco",
    age: "56",
    createdAt: "2012/06/01",
    salary: "$115,000",
  },
  {
    id: 49, name: "Jennifer Acosta",
    position: "Junior Javascript Developer",
    city: "Edinburgh",
    age: "43",
    createdAt: "2013/02/01",
    salary: "$75,650",
  },
  {
    id: 50, name: "Cara Stevens",
    position: "Sales Assistant",
    city: "New York",
    age: "46",
    createdAt: "2011/12/06",
    salary: "$145,600",
  },
  {
    id: 51, name: "Hermione Butler",
    position: "Regional Director",
    city: "London",
    age: "47",
    createdAt: "2011/03/21",
    salary: "$356,250",
  },
  {
    id: 52, name: "Lael Greer",
    position: "Systems Administrator",
    city: "London",
    age: "21",
    createdAt: "2009/02/27",
    salary: "$103,500",
  },
  {
    id: 53, name: "Jonas Alexander",
    position: "Developer",
    city: "San Francisco",
    age: "30",
    createdAt: "2010/07/14",
    salary: "$86,500",
  },
  {
    id: 54, name: "Shad Decker",
    position: "Regional Director",
    city: "Edinburgh",
    age: "51",
    createdAt: "2008/11/13",
    salary: "$183,000",
  },
  {
    id: 55, name: "Michael Bruce",
    position: "Javascript Developer",
    city: "Singapore",
    age: "29",
    createdAt: "2011/06/27",
    salary: "$183,000",
  },
  {
    id: 56, name: "Donna Snider",
    position: "Customer Support",
    city: "New York",
    age: "27",
    createdAt: "2011/01/25",
    salary: "$112,000",
  },
]
